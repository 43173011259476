import { ReactElement, ReactNode } from "react";
import { ImagePosition } from "..";
import { ViewTransition } from "../../ViewTransition";
import "./LargeCard.scss";

export interface ILargeCardProps {
	title?: string;
	content?: string;
	actions?: ReactElement;
	subtitle?: string;
	tagline?: string;
	image?: string;
	imagePosition?: ImagePosition;
	card?: ReactNode;
	imageBorder?: string;
}

export function LargeCard({ title, content, actions, subtitle, tagline, image, imagePosition, card, imageBorder }: ILargeCardProps) {
	return (
		<ViewTransition>
			<div className={`__sage-large-card-container ${imagePosition ?? ""}`}>
				<div className="col">
					{tagline && <div className="tagline">{tagline}</div>}
					{title && <div className="title">{title}</div>}
					{subtitle && <div className="subtitle">{subtitle}</div>}
					{content && <div className="content">{content}</div>}
					{actions && <div className="actions">{actions}</div>}
				</div>
				{image && (
					<div className="col">
						<img
							className={imageBorder}
							src={image}
							loading="lazy"
						/>
					</div>
				)}
				{card && <div className="col">{card}</div>}
			</div>
		</ViewTransition>
	);
}
