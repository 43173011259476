import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./ViewTransition.scss";

function useViewTransition() {
	const ref = useRef(null);
	const inViewRef = useRef(null);
	inViewRef.current = false;
	const [inView, setInView] = useState<boolean>(false);

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && !inViewRef.current) {
					inViewRef.current = true;
					observer.unobserve(ref.current);
					setTimeout(
						() => {
							setInView(true);
						},
						250 * Math.random() + 100
					);
				}
			});
		},
		{ threshold: 0.2 }
	);

	useEffect(() => {
		if (ref.current && !inView) {
			observer.observe(ref.current);
		}
		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref]);

	return [inView, ref];
}

export function ViewTransition({ children, width }: { children: ReactNode; width?: string }) {
	const [inView, ref] = useViewTransition();

	return (
		<div
			ref={ref}
			style={{
				opacity: inView ? 1 : 0,
				transform: inView ? "translateY(0)" : "translateY(1rem)",
				transition: "opacity 200ms ease-out, transform 200ms ease-out",
				width
			}}
		>
			{children}
		</div>
	);
}
