import { useEffect } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	ImagePosition,
	LargeCard,
	PageLayout,
	SecondNav,
	Section,
	Spacer
} from "@sage/shared";

export function InformationRetrievalPage() {
	useEffect(() => {
		document.title = "Information Retrieval | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/canva-background-vault.avif"}
				left={
					<BannerCard
						title={"Unleash the Power of Intelligent Data Analysis"}
						tagline={"SAGE AI"}
						content={
							"Dive deep into your data room with Sage AI's cutting-edge information retrieval system.\n\nExperience lightning-fast analysis, uncover hidden insights, and make informed decisions with confidence, all powered by our advanced AI technology."
						}
						actions={<Button href="/get-started">Get Started</Button>}
					/>
				}
				right={<div />}
			/>
			<SecondNav
				links={[
					{
						text: "Overview",
						href: "#overview"
					},
					{
						text: "Rapid Data Room Analysis",
						href: "#rapid-data-room-analysis"
					},
					{
						text: "Key Insights",
						href: "#surface-key-insights"
					},
					{
						text: "Contextual Understanding",
						href: "#contextual-understanding"
					},
					{
						text: "Deep Crawls",
						href: "#web-scraping"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>
			<PageLayout>
				<Section name="overview">
					<LargeCard
						tagline={"overview"}
						title={"Revolutionizing Data Room Analysis"}
						subtitle={"AI-Powered Insights for Investment Banking Excellence"}
						content={
							"Sage AI transforms the way investment bankers interact with data rooms, combining cutting-edge AI with industry expertise.\n\nOur platform streamlines the due diligence process, uncovering critical insights and enhancing decision-making capabilities."
						}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Intelligent Data Processing",
							subtitle: "From Information Overload to Actionable Intelligence",
							image: "https://cdn.sageai.dev/website/assets/vault-close-up-1.avif",
							content:
								"Sage AI rapidly processes vast amounts of data, categorizing and prioritizing information for efficient analysis. Our system transforms raw data into structured, easily digestible insights, saving countless hours of manual review.",
							href: "#rapid-data-room-analysis"
						},
						{
							title: "Context-Aware Information Retrieval",
							subtitle: "Finding the Needle in the Data Haystack",
							image: "https://cdn.sageai.dev/website/assets/athena-close-up-1.avif",
							content:
								"Our advanced semantic search capabilities understand the context behind your queries, delivering precisely relevant results. This intelligent retrieval system ensures you find exactly what you need, even when dealing with complex or nuanced information.",
							href: "#contextual-understanding"
						},
						{
							title: "On Demand Web Scraping",
							subtitle: "Expanding Your Information Horizon",
							image: "https://cdn.sageai.dev/website/assets/dealstream-close-up-1.avif",
							content:
								"Sage AI performs comprehensive crawls of client company websites, uncovering valuable information that may be missing from the data room. This deep dive often reveals recent strategic initiatives, detailed product information, and subtle shifts in company messaging, providing a more complete and up-to-date picture for your analysis.",
							href: "#web-scraping"
						}
					]}
				/>
			</Spacer>
			<PageLayout>
				<Section name="rapid-data-room-analysis">
					<LargeCard
						tagline={"Rapid Data Room Analysis"}
						title={"Lightning-Fast Data Processing"}
						subtitle={"From Information Overload to Actionable Insights"}
						content={
							"Sage AI's advanced RAG system swiftly navigates through expansive data rooms, processing vast amounts of information in minutes rather than days.\n\nOur technology rapidly scans, categorizes, and indexes documents of all types, from financial statements to market research reports.\n\nThis accelerated analysis not only saves countless hours of manual review but also ensures that no critical information slips through the cracks, providing a comprehensive understanding of the data landscape in record time."
						}
						image={"https://cdn.sageai.dev/website/assets/vault-with-slides.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						title={"Intelligent Document Prioritization"}
						subtitle={"Focus on What Matters Most"}
						content={
							"Our system doesn't just scan - it strategizes. Sage AI's RAG technology employs sophisticated algorithms to prioritize documents based on relevance and importance to your specific needs.\n\nBy identifying key documents and sections, it guides your attention to the most critical information first. This intelligent prioritization allows your team to quickly grasp the core elements of a deal, enabling faster decision-making and more efficient use of resources in the early stages of analysis."
						}
						image={"https://cdn.sageai.dev/website/assets/vault-with-specific-files.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="surface-key-insights">
					<LargeCard
						tagline={"Surface Key Insights"}
						title={"AI-Powered Insight Extraction"}
						subtitle={"Uncover Hidden Value in Your Data"}
						content={
							"Sage AI goes beyond mere data retrieval - it uncovers critical insights that drive deal value.\n\nOur advanced RAG system employs sophisticated natural language processing to identify key trends, anomalies, and potential opportunities within the data room.\n\nBy automatically surfacing these crucial insights, Sage AI enables your team to quickly grasp the core value drivers and risk factors of a deal.\n\nThis capability not only accelerates the due diligence process but also enhances the quality of your analysis, ensuring you're always a step ahead in negotiations."
						}
						image={"https://cdn.sageai.dev/website/assets/athena-bg.avif"}
						imagePosition={ImagePosition.Right}
					/>
					<LargeCard
						title={"Adaptive Insight Extraction"}
						subtitle={"Learning from Your Expertise"}
						content={
							"Sage AI's insight extraction evolves with your experience, leveraging knowledge from your past deals to surface the most relevant information.\n\nOur system analyzes the types of insights that have been crucial in your previous CIMs and CIPs, creating a dynamic framework for identifying similar high-value data points in new deals.\n\nThis adaptive approach ensures that the insights surfaced are not just important in general, but specifically relevant to your unique approach and industry focus.\n\nBy learning from your success patterns, Sage AI continually refines its ability to highlight the information that matters most to you and your clients."
						}
						image={"https://cdn.sageai.dev/website/assets/templates-with-sources.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Unlock the Full Potential of Your Data Room"}
							content={
								"Ready to experience the power of AI-driven insight extraction?\n\nSchedule a call today and see how Sage AI can transform your deal analysis, surfacing critical information you might otherwise miss."
							}
							actions={<Button href={"/get-started"}>Get Started</Button>}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<Section name="contextual-understanding">
					<LargeCard
						tagline={"Contextual Undetstanding"}
						title={"Contextual Understanding: Beyond Keyword Matching"}
						subtitle={"Harnessing the Power of Semantic Search"}
						content={
							"Sage AI's advanced semantic search capabilities elevate data retrieval to new heights.\n\nBy understanding context and meaning, not just keywords, our system can locate highly specific information with unprecedented accuracy.\n\nThis contextual understanding allows for more nuanced and effective data exploration, ensuring you find exactly what you need, even when you're not sure how to ask for it."
						}
					/>
					<Accordian
						sections={[
							{
								id: "nlp",
								title: "Natural Language Processing",
								content:
									"Our semantic search engine leverages cutting-edge Natural Language Processing (NLP) to comprehend the intent behind your queries.\n\nIt analyzes sentence structure, synonyms, and contextual clues to understand what you're really asking for.\n\nThis means you can phrase your questions naturally, as if you were asking a human expert, and still receive precise, relevant results.",
								image: "https://cdn.sageai.dev/website/assets/ai-2.avif"
							},
							{
								id: "concept-mapping",
								title: "Concept Mapping",
								content:
									'Sage AI goes beyond simple word matching by creating intricate concept maps from your data room contents.\n\nThis allows the system to understand relationships between different ideas and documents, even when they don\'t share exact terminology.\n\nFor example, a query about "market penetration strategies" might surface relevant documents discussing "customer acquisition tactics" or "sales funnel optimization", capturing the underlying concept rather than just matching keywords.',
								image: "https://cdn.sageai.dev/website/assets/ai-3.avif"
							},
							{
								id: "relevance-scoring",
								title: "Contextual Relevance Scoring",
								content:
									"Our system employs sophisticated algorithms to score search results based on their contextual relevance to your query.\n\nThis ensures that the most pertinent information rises to the top, saving you time and effort in sifting through results.\n\nThe scoring takes into account factors such as document type, data scope, and the strength of conceptual links to your query, providing a truly intelligent ranking of results.",
								image: "https://cdn.sageai.dev/website/assets/ai-4.avif"
							},
							{
								id: "cross-document",
								title: "Cross-Document Intelligence",
								content:
									"Sage AI's semantic search doesn't just look at documents in isolation.\n\nIt analyzes relationships and information across multiple documents, piecing together a comprehensive picture.\n\nThis cross-document intelligence allows the system to surface insights that might be spread across various files, giving you a more complete understanding of complex topics or situations.",
								image: "https://cdn.sageai.dev/website/assets/ai-1.avif"
							}
						]}
					/>
				</Section>
				<Section name="web-scraping">
					<LargeCard
						tagline={"On Demand Web Scraping"}
						title={"Real-Time Intelligence Gathering"}
						subtitle={"Expanding Your Information Horizon"}
						content={
							"Sage AI's on-demand web scraping feature takes your data analysis to the next level by seamlessly incorporating up-to-the-minute online information.\n\nWhen you need more context or the latest details about a client company, our system can instantly scour the web, gathering relevant data from public sources.\n\nThis real-time intelligence complements your data room information, providing a more comprehensive and current view of the company landscape.\n\nBy bridging the gap between internal documents and external data, Sage AI ensures you have the full picture for more informed decision-making."
						}
						image={"https://cdn.sageai.dev/website/assets/athena-with-sources.avif"}
						imagePosition={ImagePosition.Right}
					/>
				</Section>
				<Section name="next-steps">
					<LargeCard
						tagline={"Next Steps"}
						title={"Looking for More Information?"}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						},
						{
							title: "Supercharged Deliverable Creation",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content:
								"The RAG-enabled LLM can generate tailored pitch materials that resonate with the specific needs and challenges of each prospect.\n\nThis includes personalized slides, and potential outcomes that demonstrate a deep understanding of the prospect's business and industry.",
							href: "/solutions/deliverable-creation"
						},
						{
							title: "Sage AI Augments Each Stage of the Deal Cycle",
							image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
							content:
								"Sage AI was meticulously designed with a deep understanding of the daily challenges faced investment banking staff members.",
							href: "/solutions/investment-banking"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
