import { ReactNode } from "react";
import { Link } from "../Link";
import "./Button.scss";

export function Button({ children, variant, href }: { children: ReactNode; variant?: string; href?: string }) {
	return (
		<div className={`__sage-button-container ${variant}`}>
			{href ? (
				<Link href={href}>
					<img
						src={"https://cdn.ccm.vc/sage/icons/material-arrow-right-alt.svg"}
						loading="lazy"
					/>
					{children}
				</Link>
			) : (
				<button>
					<img
						src={"https://cdn.ccm.vc/sage/icons/material-arrow-right-alt.svg"}
						loading="lazy"
					/>

					{children}
				</button>
			)}
		</div>
	);
}
