import { useEffect, useRef } from "react";
import {
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	FaIcon,
	H2,
	Hero,
	ImagePosition,
	LargeCard,
	MutliTextScroll,
	PageLayout,
	Ribon,
	Section,
	Spacer,
	VideoCard
} from "@sage/shared";
import "./HomePage.scss";

export function HomePage() {
	useEffect(() => {
		document.title = "Sage AI";
	}, []);

	return (
		<section className="__sage-home-page-container">
			<PageLayout size="wide">
				<Hero>
					Specialized AI for <br />
					<MutliTextScroll
						options={["Investment Banks", "Creating CIMs", "Finding Buyers", "Financial Analysis", "Due Diligence"]}
					/>
					<H2>Not just another generic finance chatbot, Sage makes complex M&A work effortless.</H2>
					<Button
						variant={"dark"}
						href="/get-started"
					>
						Get Started
					</Button>
				</Hero>
				<Ribon>
					<FaIcon
						icon={"ballot-check"}
						size="2x"
						color={"#0051a8"}
						animation={"fa-bounce"}
					>
						Information Requests
					</FaIcon>
					<FaIcon
						icon={"folder-tree"}
						size="2x"
						color={"#00a857"}
						animation={"fa-bounce"}
					>
						Data Room Analysis
					</FaIcon>
					<FaIcon
						icon={"memo"}
						size="2x"
						color={"#30abc6"}
						animation={"fa-bounce"}
					>
						Teasers
					</FaIcon>
					<FaIcon
						icon={"file-chart-column"}
						size="2x"
						color={"#a80300"}
						animation={"fa-bounce"}
					>
						CIMs/CIPs
					</FaIcon>
					<FaIcon
						icon={"screen-users"}
						size="2x"
						color={"#f7a501"}
						animation={"fa-bounce"}
					>
						Presentations
					</FaIcon>
					<FaIcon
						icon={"file-spreadsheet"}
						size="2x"
						color={"#00a857"}
						animation={"fa-bounce"}
					>
						Financial Analysis
					</FaIcon>
					<FaIcon
						icon={"chart-pie-simple-circle-dollar"}
						size="2x"
						color={"#5700a8"}
						animation={"fa-bounce"}
					>
						Charts & Graphs
					</FaIcon>
				</Ribon>
			</PageLayout>
			{/*<SecondNav
				links={[
					{
						text: "AI Powered Solutions",
						href: "#ai-powered-solutions"
					},
					{
						text: "Industry Adoption",
						href: "#industry-adoption"
					},
					{
						text: "Sage Advantage",
						href: "#sage-advantage"
					},
					{
						text: "Next Steps",
						href: "#next-steps"
					}
				]}
			/>*/}
			<PageLayout>
				<Section name="ai-powered-solutions">
					<LargeCard
						tagline={"AI Powered Solutions"}
						title={"AI-Powered Solutions for Investment Banking"}
						subtitle={"Transforming Deal Processes with Intelligent Technology"}
						content={
							"Sage AI revolutionizes investment banking workflows, combining cutting-edge artificial intelligence with deep industry knowledge.\n\nOur platform streamlines critical processes, enhances decision-making, and delivers unparalleled efficiency."
						}
						card={
							<VideoCard
								thumbnail={"https://sageai.dev/cdn/website/assets/home-page-thumbnail.avif"}
								video={"https://sageai.dev/cdn/website/assets/workflows.mp4"}
							/>
						}
					/>
				</Section>
			</PageLayout>
			<Spacer>
				<Section name="industry-adoption">
					<LargeCard
						title={""}
						subtitle=""
					/>
					<Banner
						src={"https://cdn.sageai.dev/website/assets/industry-adoption-banner.avif"}
						size={"sm"}
						left={
							<BannerCard
								variant={"center"}
								title={"Why Boutiques Need AI"}
								content={"Don’t take our word for it, see what industry leaders are saying:"}
							/>
						}
						right={<div />}
					/>
				</Section>
			</Spacer>
			<CardList
				cards={[
					{
						title: "Goldman Sachs",
						image: "https://cdn.sageai.dev/website/assets/goldman-ai-ib-1.avif",
						content: (
							<span>
								"It might have slowed us down initially,{" "}
								<span className="highlight">but then we definitely gained a lot of velocity afterwards</span>" - Marco
								Argenti, Goldman Sachs CIO"
							</span>
						),
						href: "https://www.wsj.com/articles/goldman-sachs-deploys-its-first-generative-ai-tool-across-the-firm-cd94369b"
					},
					{
						title: "McKinsey & Company",
						image: "https://cdn.sageai.dev/website/assets/mckinsey-ai-ib-1.avif",
						content: (
							<span>
								"A leading investment bank, for example, has built a gen AI tool to help analysts write first drafts of
								pitch books. The analyst uploads all the relevant documents and then queries the chatbot to ensure it has
								the material it needs.
								<br />
								<br />
								Then, the analyst can instruct the tool to produce many of the slides that are typically needed and many
								others that reflect the specifics of the proposed investment.{" "}
								<span className="highlight">
									The tool saves analysts about 30 percent of the time they used to spend creating pitchbooks.
								</span>
								" - The McKinsey Global Institute (MGI)
							</span>
						),
						href: "https://www.mckinsey.com/industries/financial-services/our-insights/been-there-doing-that-how-corporate-and-investment-banks-are-tackling-gen-ai"
					},
					{
						title: "Jefferies",
						image: "https://cdn.sageai.dev/website/assets/jefferies.avif",
						content: (
							<span>
								<span className="highlight">
									"We win business because of the advice and insights we give to our clients."
								</span>
								<br />
								<br />
								"Generative AI is helping us develop solutions so that we can be more productive at large." - Vikram Dewan,
								Jefferies CIO
							</span>
						),
						href: "https://aws.amazon.com/solutions/case-studies/jefferies-video-case-study/"
					}
				]}
			/>
			<PageLayout>
				<Section name="sage-advantage">
					<LargeCard
						title={"AI isn't just for the big banks"}
						subtitle={"Unleashing Efficiency with Sage AI"}
						content={
							"While industry giants have embraced AI, many boutique investment banks believe they've reached peak efficiency with traditional methods.\n\nHowever, recent breakthroughs in RAG LLM technology have leveled the playing field."
						}
						image="https://cdn.sageai.dev/website/assets/templates-with-sources.avif"
						imagePosition={ImagePosition.Left}
					/>
					<Accordian
						title={"How Sage does it right"}
						subtitle={"In a sea of AI solutions, Sage AI stands out by combining three crucial elements:"}
						sections={[
							{
								id: "1",
								title: "State of the Art Models",
								content:
									"Advanced AI models form the foundation of Sage AI's capabilitie, offering unparalleled natrual language understanding and generation.\n\nThese models enable the system to comprehend complex financial concepts, extract relevant information, and generate high quality content with remarkable accuracy.\n\nWhen used directly, they perform well on generic tasks, but without deep integration with proprietary data sources, it is difficult to adapt to specialized industries.\n\nThis is where RAG comes into play...",
								image: "https://cdn.sageai.dev/website/assets/sage-triangle-lg.avif"
							},
							{
								id: "2",
								title: "RAG with your data",
								content:
									"RAG technology allows Sage AI to combine the power of large language models with your firm's proprietary data & knowledge base.\n\nThis ensures that the generated content is not only accurate and contextually relevant, but is also aligned with your firm's expertise and historical insights.",
								image: "https://cdn.sageai.dev/website/assets/sage-triangle-lg.avif"
							},
							{
								id: "3",
								title: "Purpose Built for the M&A Deal Lifecycle ",
								content:
									"This is where Sage comes in as the only true-M&A focused AI platform. We’ve built fully developed systems just for M&A that takes in all applicable data in order to help you with all aspects of the deal cycle.\n\nSage AI takes in your data, and understands who you are, who your firm is, who your client is, and what the goals of the transaction are. Then, it connects with your past deals, and your clients data room, and now can produce full deliverables in the exact manner your team would.",
								image: "https://cdn.sageai.dev/website/assets/sage-triangle-lg.avif"
							}
						]}
					/>
					<CardList
						cards={[
							{
								title: "Refine a bake-off pitch?",
								image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
								content: "Sage can do that.",
								href: "/solutions/investment-banking#bake-off"
							},
							{
								title: "Drafting an information request?",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content: "Sage can do that.",
								href: "/solutions/investment-banking#initial-due-diligence"
							},
							{
								title: "Draft a CIM?",
								image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
								href: "/solutions/investment-banking#marketing-material-creation",
								content: "Sage can do that."
							},
							{
								title: "Answer a round of buyer questions?",
								image: "https://cdn.sageai.dev/website/assets/vault-close-up-1.avif",
								content: "Sage can do that.",
								href: "/solutions/investment-banking#buyer-diligence"
							}
						]}
					/>
				</Section>
			</PageLayout>
			<Section name="next-steps">
				<Spacer>
					<Banner
						src={"https://cdn.sageai.dev/website/assets/mid-roll-banner-dark.avif"}
						size={"sm"}
						center={
							<BannerCard
								variant={"center"}
								color="_dark"
								title={"Sounds too good to be true?"}
								content={
									"That’s why we don’t expect you to believe us.\n\nMeet with a member of our team to see if Sage will work for your team in 10 minutes.\n\nOur teams work investment banking hours just like yours, so we’re available to meet with team members late at night and on weekends. Whenever works for you, works for us."
								}
								actions={<Button href={"/get-started"}>Get Started</Button>}
							/>
						}
					/>
				</Spacer>
				<Spacer>
					<CardList
						cards={[
							{
								title: "Schedule a Demo",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content: "Developed from M&A processes at industry leading firms to address real-world challenges.",
								href: "/get-started",
								cta: "Schedule"
							},
							{
								title: "Sage AI Data Security",
								content:
									"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
								href: "/security/how-we-secure-your-data",
								image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
							}
						]}
					/>
				</Spacer>
			</Section>
		</section>
	);
}
