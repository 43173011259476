import { useEffect } from "react";
import { Banner, BannerCard, CardList, ImagePosition, LargeCard, Link, PageLayout, Schedule, Section, VideoCard } from "@sage/shared";

export function GetStartedPage() {
	useEffect(() => {
		document.title = "Get Started | Sage AI";
	}, []);

	return (
		<section>
			<Banner
				src={"https://cdn.sageai.dev/website/assets/get-started-banner.avif"}
				left={
					<BannerCard
						title={"Enhance Your Investment Banking Process"}
						tagline={"SAGE AI"}
						content={
							"Streamline document creation, analyze data rooms efficiently, and gain deeper insights. Start your journey with Sage AI and experience how AI can support your investment banking workflow."
						}
					/>
				}
				right={<div />}
			/>
			<PageLayout>
				<VideoCard
					thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail.avif"}
					video={"https://sageai.dev/cdn/website/assets/workflows.mp4"}
					size={"large"}
				/>
				<Section name="schedule">
					<LargeCard
						tagline={"Schedule a call"}
						title={"Schedule a Demo Call"}
						subtitle={"Experience Sage AI in Action"}
						content={
							"Book a call with our team to see Sage AI in action.\n\nWe'll address your specific questions, demonstrate how our tools can enhance your workflow, and show you firsthand how Sage AI can transform your investment banking processes."
						}
						actions={<Link href="#calendly">Schedule</Link>}
						image={"https://cdn.sageai.dev/website/assets/demo-call-1.avif"}
						imagePosition={ImagePosition.Left}
					/>
				</Section>
				<Section name="calendly">
					<Schedule />
					<LargeCard
						tagline={"AI Powered Deal Insights"}
						title={"Unlock Critical Deal Insights with AI-Powered Analysis"}
					/>
					<CardList
						cards={[
							{
								title: "Faster Document Creation",
								image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
								content:
									"Generate high-quality CIMs and CIPs in a fraction of the time. Our AI-powered system streamlines your workflow, allowing you to focus on strategic decision-making.",
								href: "/solutions/deliverable-creation"
							},
							{
								title: "Data Room Analysis",
								image: "https://cdn.sageai.dev/website/assets/docs-project-files-close-up-1.avif",
								content:
									"Quickly surface key insights from vast amounts of data. Our advanced algorithms help you identify critical information and trends, enhancing your due diligence process.",
								href: "/solutions/information-retrieval"
							},
							{
								title: "Adaptive Templates",
								image: "https://cdn.sageai.dev/website/assets/templates-closeup-1.avif",
								content:
									"Leverage AI-driven templates that learn from your best work. Our system evolves with your needs, ensuring consistent quality across all your documents.",
								href: "/solutions/deliverable-creation#templates"
							},
							{
								title: "Bank-Grade Security",
								image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif",
								content:
									"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.",
								href: "/security/how-we-secure-your-data"
							}
						]}
					/>
				</Section>
			</PageLayout>
		</section>
	);
}
