import "./Img.scss";

export function Img({ src }: { src: string }) {
	return (
		<img
			className="__sage-image-container"
			src={src}
			loading="lazy"
		/>
	);
}
