import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ViewTransition } from "../../ViewTransition";
import "./OutlinkCard.scss";

export interface IOutlinkCardProps {
	title?: string;
	subtitle?: string;
	image?: string;
	content?: string | ReactNode;
	href?: string;
	cta?: string;
}

export function OutlinkCard({ title, subtitle, content, href, image, cta }: IOutlinkCardProps) {
	return (
		<ViewTransition>
			<div className="__sage-outlink-card-container">
				<Link href={href}>
					<div className="card-inner">
						{image && (
							<div className="image">
								<img
									src={image}
									loading="lazy"
								/>
							</div>
						)}
						{title && <div className="title">{title}</div>}
						{subtitle && <div className="subtitle">{subtitle}</div>}
						{content && <div className="content">{content}</div>}
						{href && (
							<div className="action-arrow">
								<div className="read-more">{cta ?? "Read More"}</div>
								<img
									src={"https://cdn.ccm.vc/sage/icons/material-arrow-right-alt.svg"}
									loading="lazy"
								/>
							</div>
						)}
					</div>
				</Link>
			</div>
		</ViewTransition>
	);
}

function Link({ href, children }: { href?: string; children: ReactNode }) {
	if (href?.includes("#")) {
		return <a href={href}>{children}</a>;
	} else {
		return (
			<RouterLink
				to={href}
				target={href?.includes("https") ? "_blank" : undefined}
			>
				{children}
			</RouterLink>
		);
	}
}
