import { CardList } from "../Card";

export function ServicesCards({
	deliverableCreation,
	informationRetrieval
}: {
	deliverableCreation?: string;
	informationRetrieval?: string;
}) {
	return (
		<CardList
			cards={[
				{
					title: "Deliverable Creation",
					image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
					content:
						deliverableCreation ||
						"The RAG-enabled LLM can generate tailored pitch materials that resonate with the specific needs and challenges of each prospect. This includes personalized slides, and potential outcomes that demonstrate a deep understanding of the prospect's business and industry.",
					href: "/solutions/deliverable-creation"
				},
				{
					title: "Information Retrieval",
					image: "https://cdn.sageai.dev/website/assets/athena-close-up-1.avif",
					content:
						informationRetrieval ||
						"The RAG model can quickly pull relevant information from the vast company database, reducing the time investment bankers spend on manual research. This enables them to focus more on strategic decision-making and relationship building.",
					href: "/solutions/information-retrieval"
				}
			]}
		/>
	);
}
