import { ViewTransition } from "../../ViewTransition";
import "./VideoCard.scss";

export interface IVideoCardProps {
	video: string;
	thumbnail?: string;
	size?: string;
}

export function VideoCard({ video, thumbnail, size }: IVideoCardProps) {
	return (
		<ViewTransition>
			<div className={`__sage-video-card-container ${size}`}>
				<div className="video-card-inner">
					<video
						controls
						poster={thumbnail}
					>
						<source src={video} />
					</video>
				</div>
			</div>
		</ViewTransition>
	);
}
